.unfootprint-link {
  text-decoration: none; }

.unfootprint-widget {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  text-align: center;
  box-sizing: border-box;
  background-size: contain;
  display: table;
  /* Revert large to medium if screen is small. */ }
  .unfootprint-widget .header {
    display: table-row; }
  .unfootprint-widget .spacer {
    background-color: rgba(0, 0, 0, 0.15) !important; }
  .unfootprint-widget .multi-column {
    display: flex; }
    .unfootprint-widget .multi-column .content {
      width: 100%; }
    .unfootprint-widget .multi-column .column {
      flex: 1 1 0px;
      flex-grow: 1;
      padding: 0 5px;
      border-right: 1px solid rgba(255, 255, 255, 0.07); }
    .unfootprint-widget .multi-column .column:last-child {
      border-right: 0; }
  .unfootprint-widget .row {
    display: table-row;
    /* 
     * The image container defines how much room the image div is allowed.
     * By specifying border-box, the padding is included in the 30% width, so it doesn't grow (keeping it fit next to another 70% element).
     * By having a container div, the inner .image can use contain to use the available space, while still having a padding.
     * When you specify the padding directly on .image, the background image will just use that extra padding (so it has no effect).
     */ }
    .unfootprint-widget .row .image-container {
      float: left;
      width: 30%;
      height: 100%;
      padding: 5px 2px;
      box-sizing: border-box;
      display: flex;
      /* Display flex to center the div. */
      justify-content: center;
      /* Horizontal centering. */
      align-items: center;
      /* Vertical centering. */ }
    .unfootprint-widget .row .image {
      background: url(https://unfootprint.com/widgets/v1/assets/tree4.png) no-repeat center;
      background-size: contain;
      width: 100%;
      height: 100%;
      max-width: 55px;
      max-height: 60px; }
      .unfootprint-widget .row .image.footprint {
        background: url(https://unfootprint.com/widgets/v1/assets/footprint.png) no-repeat center;
        background-size: contain; }
      .unfootprint-widget .row .image.airplane {
        background: url(https://unfootprint.com/widgets/v1/assets/airplane.png) no-repeat center;
        background-size: contain; }
      .unfootprint-widget .row .image.car {
        background: url(https://unfootprint.com/widgets/v1/assets/car.png) no-repeat center;
        background-size: contain; }
    .unfootprint-widget .row .content {
      width: 70%;
      float: left;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center; }
      .unfootprint-widget .row .content .columns {
        display: flex;
        flex-wrap: wrap; }
        .unfootprint-widget .row .content .columns .description {
          text-align: left;
          display: inline-block;
          align-self: flex-end; }
      .unfootprint-widget .row .content .columns > * {
        flex: 1; }
  .unfootprint-widget.dark {
    background: #1a2b3c;
    color: #eee;
    border: 6px solid #0c1823; }
    .unfootprint-widget.dark .header {
      background: #0c1823; }
    .unfootprint-widget.dark .comment {
      color: #aaa; }
  .unfootprint-widget.light {
    background: #fff;
    color: #97c55a;
    border: 6px solid #97c55a; }
    .unfootprint-widget.light .header {
      color: #1a2b3c;
      padding: 5px 0; }
      .unfootprint-widget.light .header > div {
        margin-top: 5px; }
    .unfootprint-widget.light .row .image.airplane {
      background: url(https://unfootprint.com/widgets/v1/assets/airplane-green.png) no-repeat center;
      background-size: 80%; }
    .unfootprint-widget.light .row .image.image.car {
      background: url(https://unfootprint.com/widgets/v1/assets/car-green.png) no-repeat center;
      background-size: 80%; }
    .unfootprint-widget.light .comment {
      color: #1a2b3c; }
  .unfootprint-widget.green {
    background: #97c55a;
    color: #fff;
    border: 6px solid #8cbb53; }
    .unfootprint-widget.green .header {
      background: #8cbb53; }
    .unfootprint-widget.green .row .image.footprint {
      background: url(https://unfootprint.com/widgets/v1/assets/footprint-white.png) no-repeat center;
      background-size: 80%; }
    .unfootprint-widget.green .row .image.airplane {
      background: url(https://unfootprint.com/widgets/v1/assets/airplane-white.png) no-repeat center;
      background-size: 80%; }
    .unfootprint-widget.green .row .image.car {
      background: url(https://unfootprint.com/widgets/v1/assets/car-white.png) no-repeat center;
      background-size: 80%; }
    .unfootprint-widget.green .comment {
      color: #307231; }
  .unfootprint-widget.small {
    width: 164px;
    height: 101px; }
    .unfootprint-widget.small .header {
      font-size: 12px;
      height: 15px; }
    .unfootprint-widget.small .spacer {
      height: 25px;
      line-height: 25px; }
    .unfootprint-widget.small .comment {
      font-size: 13px; }
    .unfootprint-widget.small .value {
      font-size: 25px; }
  .unfootprint-widget.medium {
    width: 250px;
    height: 154px; }
    .unfootprint-widget.medium .header {
      font-size: 15px;
      height: 25px; }
    .unfootprint-widget.medium .spacer {
      font-size: 13px;
      height: 30px;
      line-height: 30px;
      /* Same as header height, to center vertically. */ }
    .unfootprint-widget.medium .comment {
      font-size: 13px; }
    .unfootprint-widget.medium .value {
      font-size: 29px; }
  .unfootprint-widget.large {
    width: 500px;
    height: 309px; }
    .unfootprint-widget.large .header {
      font-size: 15px;
      height: 25px; }
    .unfootprint-widget.large .spacer {
      line-height: 25px; }
    .unfootprint-widget.large .comment {
      font-size: 13px; }
    .unfootprint-widget.large .value {
      font-size: 29px; }
  @media only screen and (max-width: 540px) {
    .unfootprint-widget.large {
      width: 250px;
      height: 154px; }
      .unfootprint-widget.large .header {
        font-size: 15px;
        height: 25px; }
      .unfootprint-widget.large .spacer {
        font-size: 13px;
        height: 30px;
        line-height: 30px;
        /* Same as header height, to center vertically. */ }
      .unfootprint-widget.large .comment {
        font-size: 13px; }
      .unfootprint-widget.large .value {
        font-size: 29px; }
    .unfootprint-widget .hide-small {
      display: none; } }
  .unfootprint-widget.full-width {
    width: 100% !important; }
  .unfootprint-widget.auto-height {
    height: auto; }
    .unfootprint-widget.auto-height .content {
      padding: 15px 0;
      height: auto; }
  .unfootprint-widget.rounded-small {
    border-radius: 100%;
    width: 150px;
    height: 150px; }
  .unfootprint-widget.rounded-medium {
    border-radius: 100%;
    width: 300px;
    height: 300px; }
  .unfootprint-widget.rounded-medium {
    border-radius: 100%;
    width: 500px;
    height: 500px; }
  .unfootprint-widget.rounded-s {
    border-radius: 5px; }
  .unfootprint-widget.rounded-m {
    border-radius: 10px; }
  .unfootprint-widget.rounded-l {
    border-radius: 15px; }
